import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, NgModule, SkipSelf } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpRequest, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { isArray, includes, isUndefined, isNull, defaults, each, extend, find, has, initial, last, clone, reduce, isBoolean, keys, isEmpty, forEach, isObject, isFunction, map as map$1, bind, union, values, pick, isNumber, omit, every, get } from 'lodash';
import { throwError, BehaviorSubject } from 'rxjs';
import { filter, map, catchError } from 'rxjs/operators';
const RESTANGULAR = new InjectionToken('restangularWithConfig');
function RestangularFactory([callbackOrServices, callback]) {
  let arrServices = [];
  let fn = callbackOrServices;
  if (isArray(callbackOrServices)) {
    arrServices = callbackOrServices;
    fn = callback;
  }
  return {
    fn,
    arrServices
  };
}
class RestangularHelper {
  static createRequest(options) {
    const requestQueryParams = RestangularHelper.createRequestQueryParams(options.params);
    const requestHeaders = RestangularHelper.createRequestHeaders(options.headers);
    const methodName = options.method.toUpperCase();
    const withCredentials = options.withCredentials || false;
    let request = new HttpRequest(methodName, options.url, options.data, {
      headers: requestHeaders,
      params: requestQueryParams,
      responseType: options.responseType,
      withCredentials
    });
    if (['GET', 'DELETE', 'HEAD', 'JSONP', 'OPTIONS'].indexOf(methodName) >= 0) {
      request = new HttpRequest(methodName, options.url, {
        headers: requestHeaders,
        params: requestQueryParams,
        responseType: options.responseType,
        withCredentials
      });
    }
    return request;
  }
  static createRequestQueryParams(queryParams) {
    const requestQueryParams = Object.assign({}, queryParams);
    let search = new HttpParams();
    for (const key in requestQueryParams) {
      let value = requestQueryParams[key];
      if (Array.isArray(value)) {
        value.forEach(function (val) {
          search = search.append(key, val);
        });
      } else {
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        search = search.append(key, value);
      }
    }
    return search;
  }
  static createRequestHeaders(headers) {
    for (const key in headers) {
      const value = headers[key];
      if (typeof value === 'undefined') {
        delete headers[key];
      }
    }
    return new HttpHeaders(Object.assign({}, headers));
  }
}
class RestangularHttp {
  constructor(http) {
    this.http = http;
  }
  createRequest(options) {
    const request = RestangularHelper.createRequest(options);
    return this.request(request);
  }
  request(request) {
    return this.http.handle(request).pipe(filter(event => event instanceof HttpResponse), map(response => {
      if (!response.ok) {
        return throwError(new HttpErrorResponse(response));
      }
      return response;
    }), map(response => {
      response.config = {
        params: request
      };
      return response;
    }), catchError(err => {
      err.request = request;
      err.data = err.error;
      err.repeatRequest = newRequest => {
        return this.request(newRequest || request);
      };
      return throwError(err);
    }));
  }
}
/** @nocollapse */
RestangularHttp.ɵfac = function RestangularHttp_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RestangularHttp)(i0.ɵɵinject(i1.HttpBackend));
};
/** @nocollapse */
RestangularHttp.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: RestangularHttp,
  factory: RestangularHttp.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RestangularHttp, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.HttpBackend
    }];
  }, null);
})();
function RestangularConfigurer(object, configuration) {
  object.configuration = configuration;
  /**
   * Those are HTTP safe methods for which there is no need to pass any data with the request.
   */
  const safeMethods = ['get', 'head', 'options', 'trace', 'getlist'];
  configuration.isSafe = function (operation) {
    return includes(safeMethods, operation.toLowerCase());
  };
  const absolutePattern = /^https?:\/\//i;
  configuration.isAbsoluteUrl = function (string) {
    return isUndefined(configuration.absoluteUrl) || isNull(configuration.absoluteUrl) ? string && absolutePattern.test(string) : configuration.absoluteUrl;
  };
  configuration.absoluteUrl = isUndefined(configuration.absoluteUrl) ? true : configuration.absoluteUrl;
  object.setSelfLinkAbsoluteUrl = function (value) {
    configuration.absoluteUrl = value;
  };
  /**
   * This is the BaseURL to be used with Restangular
   */
  configuration.baseUrl = isUndefined(configuration.baseUrl) ? '' : configuration.baseUrl;
  object.setBaseUrl = function (newBaseUrl) {
    configuration.baseUrl = /\/$/.test(newBaseUrl) ? newBaseUrl.substring(0, newBaseUrl.length - 1) : newBaseUrl;
    return this;
  };
  /**
   * Sets the extra fields to keep from the parents
   */
  configuration.extraFields = configuration.extraFields || [];
  object.setExtraFields = function (newExtraFields) {
    configuration.extraFields = newExtraFields;
    return this;
  };
  /**
   * Some default $http parameter to be used in EVERY call
   **/
  configuration.defaultHttpFields = configuration.defaultHttpFields || {};
  object.setDefaultHttpFields = function (values) {
    configuration.defaultHttpFields = values;
    return this;
  };
  /**
   * Always return plain data, no restangularized object
   **/
  configuration.plainByDefault = configuration.plainByDefault || false;
  object.setPlainByDefault = function (value) {
    configuration.plainByDefault = value === true ? true : false;
    return this;
  };
  configuration.withHttpValues = function (httpLocalConfig, obj) {
    return defaults(obj, httpLocalConfig, configuration.defaultHttpFields);
  };
  configuration.encodeIds = isUndefined(configuration.encodeIds) ? true : configuration.encodeIds;
  object.setEncodeIds = function (encode) {
    configuration.encodeIds = encode;
  };
  configuration.defaultRequestParams = configuration.defaultRequestParams || {
    get: {},
    post: {},
    put: {},
    remove: {},
    common: {}
  };
  object.setDefaultRequestParams = function (param1, param2) {
    let methods = [];
    const params = param2 || param1;
    if (!isUndefined(param2)) {
      if (isArray(param1)) {
        methods = param1;
      } else {
        methods.push(param1);
      }
    } else {
      methods.push('common');
    }
    each(methods, function (method) {
      configuration.defaultRequestParams[method] = params;
    });
    return this;
  };
  object.requestParams = configuration.defaultRequestParams;
  configuration.defaultHeaders = configuration.defaultHeaders || {};
  object.setDefaultHeaders = function (headers) {
    configuration.defaultHeaders = headers;
    object.defaultHeaders = configuration.defaultHeaders;
    return this;
  };
  object.defaultHeaders = configuration.defaultHeaders;
  /**
   * Method overriders response Method
   **/
  configuration.defaultResponseMethod = configuration.defaultResponseMethod || 'promise';
  object.setDefaultResponseMethod = function (method) {
    configuration.defaultResponseMethod = method;
    object.defaultResponseMethod = configuration.defaultResponseMethod;
    return this;
  };
  object.defaultResponseMethod = configuration.defaultResponseMethod;
  /**
   * Method overriders will set which methods are sent via POST with an X-HTTP-Method-Override
   **/
  configuration.methodOverriders = configuration.methodOverriders || [];
  object.setMethodOverriders = function (values) {
    const overriders = extend([], values);
    if (configuration.isOverridenMethod('delete', overriders)) {
      overriders.push('remove');
    }
    configuration.methodOverriders = overriders;
    return this;
  };
  configuration.jsonp = isUndefined(configuration.jsonp) ? false : configuration.jsonp;
  object.setJsonp = function (active) {
    configuration.jsonp = active;
  };
  configuration.isOverridenMethod = function (method, values) {
    const search = values || configuration.methodOverriders;
    return !isUndefined(find(search, function (one) {
      return one.toLowerCase() === method.toLowerCase();
    }));
  };
  /**
   * Sets the URL creator type. For now, only Path is created. In the future we'll have queryParams
   **/
  configuration.urlCreator = configuration.urlCreator || 'path';
  object.setUrlCreator = function (name) {
    if (!has(configuration.urlCreatorFactory, name)) {
      throw new Error('URL Path selected isn\'t valid');
    }
    configuration.urlCreator = name;
    return this;
  };
  /**
   * You can set the restangular fields here. The 3 required fields for Restangular are:
   *
   * id: Id of the element
   * route: name of the route of this element
   * parentResource: the reference to the parent resource
   *
   *  All of this fields except for id, are handled (and created) by Restangular. By default,
   *  the field values will be id, route and parentResource respectively
   */
  configuration.restangularFields = configuration.restangularFields || {
    id: 'id',
    route: 'route',
    parentResource: 'parentResource',
    restangularCollection: 'restangularCollection',
    cannonicalId: '__cannonicalId',
    etag: 'restangularEtag',
    selfLink: 'href',
    get: 'get',
    getList: 'getList',
    put: 'put',
    post: 'post',
    remove: 'remove',
    head: 'head',
    trace: 'trace',
    options: 'options',
    patch: 'patch',
    getRestangularUrl: 'getRestangularUrl',
    getRequestedUrl: 'getRequestedUrl',
    putElement: 'putElement',
    addRestangularMethod: 'addRestangularMethod',
    getParentList: 'getParentList',
    clone: 'clone',
    ids: 'ids',
    httpConfig: '_$httpConfig',
    reqParams: 'reqParams',
    one: 'one',
    all: 'all',
    several: 'several',
    oneUrl: 'oneUrl',
    allUrl: 'allUrl',
    customPUT: 'customPUT',
    customPATCH: 'customPATCH',
    customPOST: 'customPOST',
    customDELETE: 'customDELETE',
    customGET: 'customGET',
    customGETLIST: 'customGETLIST',
    customOperation: 'customOperation',
    doPUT: 'doPUT',
    doPATCH: 'doPATCH',
    doPOST: 'doPOST',
    doDELETE: 'doDELETE',
    doGET: 'doGET',
    doGETLIST: 'doGETLIST',
    fromServer: 'fromServer',
    withConfig: 'withConfig',
    withHttpConfig: 'withHttpConfig',
    singleOne: 'singleOne',
    plain: 'plain',
    save: 'save',
    restangularized: 'restangularized'
  };
  object.setRestangularFields = function (resFields) {
    configuration.restangularFields = extend({}, configuration.restangularFields, resFields);
    return this;
  };
  configuration.isRestangularized = function (obj) {
    return !!obj[configuration.restangularFields.restangularized];
  };
  configuration.setFieldToElem = function (field, elem, value) {
    const properties = field.split('.');
    let idValue = elem;
    each(initial(properties), function (prop) {
      idValue[prop] = {};
      idValue = idValue[prop];
    });
    const index = last(properties);
    idValue[index] = value;
    return this;
  };
  configuration.getFieldFromElem = function (field, elem) {
    const properties = field.split('.');
    let idValue = elem;
    each(properties, function (prop) {
      if (idValue) {
        idValue = idValue[prop];
      }
    });
    return clone(idValue);
  };
  configuration.setIdToElem = function (elem, id /*, route */) {
    configuration.setFieldToElem(configuration.restangularFields.id, elem, id);
    return this;
  };
  configuration.getIdFromElem = function (elem) {
    return configuration.getFieldFromElem(configuration.restangularFields.id, elem);
  };
  configuration.isValidId = function (elemId) {
    return '' !== elemId && !isUndefined(elemId) && !isNull(elemId);
  };
  configuration.setUrlToElem = function (elem, url /*, route */) {
    configuration.setFieldToElem(configuration.restangularFields.selfLink, elem, url);
    return this;
  };
  configuration.getUrlFromElem = function (elem) {
    return configuration.getFieldFromElem(configuration.restangularFields.selfLink, elem);
  };
  configuration.useCannonicalId = isUndefined(configuration.useCannonicalId) ? false : configuration.useCannonicalId;
  object.setUseCannonicalId = function (value) {
    configuration.useCannonicalId = value;
    return this;
  };
  configuration.getCannonicalIdFromElem = function (elem) {
    const cannonicalId = elem[configuration.restangularFields.cannonicalId];
    const actualId = configuration.isValidId(cannonicalId) ? cannonicalId : configuration.getIdFromElem(elem);
    return actualId;
  };
  /**
   * Sets the Response parser. This is used in case your response isn't directly the data.
   * For example if you have a response like {meta: {'meta'}, data: {name: 'Gonto'}}
   * you can extract this data which is the one that needs wrapping
   *
   * The ResponseExtractor is a function that receives the response and the method executed.
   */
  configuration.responseInterceptors = configuration.responseInterceptors ? [...configuration.responseInterceptors] : [];
  configuration.defaultResponseInterceptor = function (data /*, operation, what, url, response, subject */) {
    return data || {};
  };
  configuration.responseExtractor = function (data, operation, what, url, response, subject) {
    const interceptors = clone(configuration.responseInterceptors);
    interceptors.push(configuration.defaultResponseInterceptor);
    let theData = data;
    each(interceptors, function (interceptor) {
      theData = interceptor(theData, operation, what, url, response, subject);
    });
    return theData;
  };
  object.addResponseInterceptor = function (extractor) {
    configuration.responseInterceptors.push(extractor);
    return this;
  };
  configuration.errorInterceptors = configuration.errorInterceptors ? [...configuration.errorInterceptors] : [];
  object.addErrorInterceptor = function (interceptor) {
    configuration.errorInterceptors = [interceptor, ...configuration.errorInterceptors];
    return this;
  };
  object.setResponseInterceptor = object.addResponseInterceptor;
  object.setResponseExtractor = object.addResponseInterceptor;
  object.setErrorInterceptor = object.addErrorInterceptor;
  /**
   * Response interceptor is called just before resolving promises.
   */
  /**
   * Request interceptor is called before sending an object to the server.
   */
  configuration.requestInterceptors = configuration.requestInterceptors ? [...configuration.requestInterceptors] : [];
  configuration.defaultInterceptor = function (element, operation, path, url, headers, params, httpConfig) {
    return {
      element: element,
      headers: headers,
      params: params,
      httpConfig: httpConfig
    };
  };
  configuration.fullRequestInterceptor = function (element, operation, path, url, headers, params, httpConfig) {
    const interceptors = clone(configuration.requestInterceptors);
    const defaultRequest = configuration.defaultInterceptor(element, operation, path, url, headers, params, httpConfig);
    return reduce(interceptors, function (request, interceptor) {
      const returnInterceptor = interceptor(request.element, operation, path, url, request.headers, request.params, request.httpConfig);
      return extend(request, returnInterceptor);
    }, defaultRequest);
  };
  object.addRequestInterceptor = function (interceptor) {
    configuration.requestInterceptors.push(function (elem, operation, path, url, headers, params, httpConfig) {
      return {
        headers: headers,
        params: params,
        element: interceptor(elem, operation, path, url),
        httpConfig: httpConfig
      };
    });
    return this;
  };
  object.setRequestInterceptor = object.addRequestInterceptor;
  object.addFullRequestInterceptor = function (interceptor) {
    configuration.requestInterceptors.push(interceptor);
    return this;
  };
  object.setFullRequestInterceptor = object.addFullRequestInterceptor;
  configuration.onBeforeElemRestangularized = configuration.onBeforeElemRestangularized || function (elem) {
    return elem;
  };
  object.setOnBeforeElemRestangularized = function (post) {
    configuration.onBeforeElemRestangularized = post;
    return this;
  };
  object.setRestangularizePromiseInterceptor = function (interceptor) {
    configuration.restangularizePromiseInterceptor = interceptor;
    return this;
  };
  /**
   * This method is called after an element has been "Restangularized".
   *
   * It receives the element, a boolean indicating if it's an element or a collection
   * and the name of the model
   *
   */
  configuration.onElemRestangularized = configuration.onElemRestangularized || function (elem) {
    return elem;
  };
  object.setOnElemRestangularized = function (post) {
    configuration.onElemRestangularized = post;
    return this;
  };
  configuration.shouldSaveParent = configuration.shouldSaveParent || function () {
    return true;
  };
  object.setParentless = function (values) {
    if (isArray(values)) {
      configuration.shouldSaveParent = function (route) {
        return !includes(values, route);
      };
    } else if (isBoolean(values)) {
      configuration.shouldSaveParent = function () {
        return !values;
      };
    }
    return this;
  };
  /**
   * This lets you set a suffix to every request.
   *
   * For example, if your api requires that for JSon requests you do /users/123.json, you can set that
   * in here.
   *
   *
   * By default, the suffix is null
   */
  configuration.suffix = isUndefined(configuration.suffix) ? null : configuration.suffix;
  object.setRequestSuffix = function (newSuffix) {
    configuration.suffix = newSuffix;
    return this;
  };
  /**
   * Add element transformers for certain routes.
   */
  configuration.transformers = configuration.transformers || {};
  object.addElementTransformer = function (type, secondArg, thirdArg) {
    let isCollection = null;
    let transformer = null;
    if (arguments.length === 2) {
      transformer = secondArg;
    } else {
      transformer = thirdArg;
      isCollection = secondArg;
    }
    let typeTransformers = configuration.transformers[type];
    if (!typeTransformers) {
      typeTransformers = configuration.transformers[type] = [];
    }
    typeTransformers.push(function (coll, elem) {
      if (isNull(isCollection) || coll === isCollection) {
        return transformer(elem);
      }
      return elem;
    });
    return object;
  };
  object.extendCollection = function (route, fn) {
    return object.addElementTransformer(route, true, fn);
  };
  object.extendModel = function (route, fn) {
    return object.addElementTransformer(route, false, fn);
  };
  configuration.transformElem = function (elem, isCollection, route, Restangular, force) {
    if (!force && !configuration.transformLocalElements && !elem[configuration.restangularFields.fromServer]) {
      return elem;
    }
    const typeTransformers = configuration.transformers[route];
    let changedElem = elem;
    if (typeTransformers) {
      each(typeTransformers, function (transformer) {
        changedElem = transformer(isCollection, changedElem);
      });
    }
    return configuration.onElemRestangularized(changedElem, isCollection, route, Restangular);
  };
  configuration.transformLocalElements = isUndefined(configuration.transformLocalElements) ? false : configuration.transformLocalElements;
  object.setTransformOnlyServerElements = function (active) {
    configuration.transformLocalElements = !active;
  };
  configuration.fullResponse = isUndefined(configuration.fullResponse) ? false : configuration.fullResponse;
  object.setFullResponse = function (full) {
    configuration.fullResponse = full;
    return this;
  };
  // Internal values and functions
  configuration.urlCreatorFactory = {};
  /**
   * Base URL Creator. Base prototype for everything related to it
   **/
  const BaseCreator = function () {};
  BaseCreator.prototype.setConfig = function (config) {
    this.config = config;
    return this;
  };
  BaseCreator.prototype.parentsArray = function (current) {
    const parents = [];
    while (current) {
      parents.push(current);
      current = current[this.config.restangularFields.parentResource];
    }
    return parents.reverse();
  };
  function RestangularResource(config, $http, url, configurer) {
    const resource = {};
    each(keys(configurer), function (key) {
      const value = configurer[key];
      // Add default parameters
      value.params = extend({}, value.params, config.defaultRequestParams[value.method.toLowerCase()]);
      // We don't want the ? if no params are there
      if (isEmpty(value.params)) {
        delete value.params;
      }
      if (config.isSafe(value.method)) {
        resource[key] = function () {
          const resultConfig = extend(value, {
            url: url
          });
          return $http.createRequest(resultConfig);
        };
      } else {
        resource[key] = function (data) {
          const resultConfig = extend(value, {
            url: url,
            data: data
          });
          return $http.createRequest(resultConfig);
        };
      }
    });
    return resource;
  }
  BaseCreator.prototype.resource = function (current, $http, localHttpConfig, callHeaders, callParams, what, etag, operation) {
    const params = defaults(callParams || {}, this.config.defaultRequestParams.common);
    const headers = defaults(callHeaders || {}, this.config.defaultHeaders);
    if (etag) {
      if (!configuration.isSafe(operation)) {
        headers['If-Match'] = etag;
      } else {
        headers['If-None-Match'] = etag;
      }
    }
    let url = this.base(current);
    if (what) {
      let add = '';
      if (!/\/$/.test(url)) {
        add += '/';
      }
      add += what;
      url += add;
    }
    if (this.config.suffix && url.indexOf(this.config.suffix, url.length - this.config.suffix.length) === -1 && !this.config.getUrlFromElem(current)) {
      url += this.config.suffix;
    }
    current[this.config.restangularFields.httpConfig] = undefined;
    return RestangularResource(this.config, $http, url, {
      getList: this.config.withHttpValues(localHttpConfig, {
        method: 'GET',
        params: params,
        headers: headers
      }),
      get: this.config.withHttpValues(localHttpConfig, {
        method: 'GET',
        params: params,
        headers: headers
      }),
      jsonp: this.config.withHttpValues(localHttpConfig, {
        method: 'jsonp',
        params: params,
        headers: headers
      }),
      put: this.config.withHttpValues(localHttpConfig, {
        method: 'PUT',
        params: params,
        headers: headers
      }),
      post: this.config.withHttpValues(localHttpConfig, {
        method: 'POST',
        params: params,
        headers: headers
      }),
      remove: this.config.withHttpValues(localHttpConfig, {
        method: 'DELETE',
        params: params,
        headers: headers
      }),
      head: this.config.withHttpValues(localHttpConfig, {
        method: 'HEAD',
        params: params,
        headers: headers
      }),
      trace: this.config.withHttpValues(localHttpConfig, {
        method: 'TRACE',
        params: params,
        headers: headers
      }),
      options: this.config.withHttpValues(localHttpConfig, {
        method: 'OPTIONS',
        params: params,
        headers: headers
      }),
      patch: this.config.withHttpValues(localHttpConfig, {
        method: 'PATCH',
        params: params,
        headers: headers
      })
    });
  };
  /**
   * This is the Path URL creator. It uses Path to show Hierarchy in the Rest API.
   * This means that if you have an Account that then has a set of Buildings, a URL to a building
   * would be /accounts/123/buildings/456
   **/
  const Path = function () {};
  Path.prototype = new BaseCreator();
  Path.prototype.normalizeUrl = function (url) {
    const parts = /((?:http[s]?:)?\/\/)?(.*)?/.exec(url);
    parts[2] = parts[2].replace(/[\\\/]+/g, '/');
    return typeof parts[1] !== 'undefined' ? parts[1] + parts[2] : parts[2];
  };
  Path.prototype.base = function (current) {
    const __this = this;
    return reduce(this.parentsArray(current), function (acum, elem) {
      let elemUrl;
      const elemSelfLink = __this.config.getUrlFromElem(elem);
      if (elemSelfLink) {
        if (__this.config.isAbsoluteUrl(elemSelfLink)) {
          return elemSelfLink;
        } else {
          elemUrl = elemSelfLink;
        }
      } else {
        elemUrl = elem[__this.config.restangularFields.route];
        if (elem[__this.config.restangularFields.restangularCollection]) {
          const ids = elem[__this.config.restangularFields.ids];
          if (ids) {
            elemUrl += '/' + ids.join(',');
          }
        } else {
          let elemId;
          if (__this.config.useCannonicalId) {
            elemId = __this.config.getCannonicalIdFromElem(elem);
          } else {
            elemId = __this.config.getIdFromElem(elem);
          }
          if (configuration.isValidId(elemId) && !elem.singleOne) {
            elemUrl += '/' + (__this.config.encodeIds ? encodeURIComponent(elemId) : elemId);
          }
        }
      }
      acum = acum.replace(/\/$/, '') + '/' + elemUrl;
      return __this.normalizeUrl(acum);
    }, this.config.baseUrl);
  };
  Path.prototype.fetchUrl = function (current, what) {
    let baseUrl = this.base(current);
    if (what) {
      baseUrl += '/' + what;
    }
    return baseUrl;
  };
  Path.prototype.fetchRequestedUrl = function (current, what) {
    const url = this.fetchUrl(current, what);
    const params = current[configuration.restangularFields.reqParams];
    // From here on and until the end of fetchRequestedUrl,
    // the code has been kindly borrowed from angular.js
    // The reason for such code bloating is coherence:
    //   If the user were to use this for cache management, the
    //   serialization of parameters would need to be identical
    //   to the one done by angular for cache keys to match.
    function sortedKeys(obj) {
      const resultKeys = [];
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          resultKeys.push(key);
        }
      }
      return resultKeys.sort();
    }
    function forEachSorted(obj, iterator, context) {
      const sortedKeysArray = sortedKeys(obj);
      for (let i = 0; i < sortedKeysArray.length; i++) {
        iterator.call(context, obj[sortedKeysArray[i]], sortedKeysArray[i]);
      }
      return sortedKeysArray;
    }
    function encodeUriQuery(val, pctEncodeSpaces) {
      return encodeURIComponent(val).replace(/%40/gi, '@').replace(/%3A/gi, ':').replace(/%24/g, '$').replace(/%2C/gi, ',').replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
    }
    if (!params) {
      return url + (this.config.suffix || '');
    }
    const parts = [];
    forEachSorted(params, function (value, key) {
      if (value === null || value === undefined) {
        return;
      }
      if (!isArray(value)) {
        value = [value];
      }
      forEach(value, function (v) {
        if (isObject(v)) {
          v = JSON.stringify(v);
        }
        parts.push(encodeUriQuery(key) + '=' + encodeUriQuery(v));
      });
    });
    return url + (this.config.suffix || '') + (url.indexOf('?') === -1 ? '?' : '&') + parts.join('&');
  };
  configuration.urlCreatorFactory.path = Path;
}
class Restangular {
  constructor(configObj, injector, http) {
    this.configObj = configObj;
    this.injector = injector;
    this.http = http;
    this.provider = new providerConfig(this.http);
    const element = this.provider.$get();
    Object.assign(this, element);
    this.setDefaultConfig();
  }
  setDefaultConfig() {
    if (!this.configObj || !isFunction(this.configObj.fn)) {
      return;
    }
    const arrDI = map$1(this.configObj.arrServices, services => {
      return this.injector.get(services);
    });
    this.configObj.fn(...[this.provider, ...arrDI]);
  }
}
/** @nocollapse */
Restangular.ɵfac = function Restangular_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Restangular)(i0.ɵɵinject(RESTANGULAR, 8), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(RestangularHttp));
};
/** @nocollapse */
Restangular.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: Restangular,
  factory: Restangular.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Restangular, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [RESTANGULAR]
      }]
    }, {
      type: i0.Injector
    }, {
      type: RestangularHttp
    }];
  }, null);
})();
function providerConfig($http) {
  const globalConfiguration = {};
  RestangularConfigurer(this, globalConfiguration);
  this.$get = $get;
  function $get() {
    function createServiceForConfiguration(config) {
      const service = {};
      const urlHandler = new config.urlCreatorFactory[config.urlCreator]();
      urlHandler.setConfig(config);
      function restangularizeBase(parent, elem, route, reqParams, fromServer) {
        elem[config.restangularFields.route] = route;
        elem[config.restangularFields.getRestangularUrl] = bind(urlHandler.fetchUrl, urlHandler, elem);
        elem[config.restangularFields.getRequestedUrl] = bind(urlHandler.fetchRequestedUrl, urlHandler, elem);
        elem[config.restangularFields.addRestangularMethod] = bind(addRestangularMethodFunction, elem);
        elem[config.restangularFields.clone] = bind(copyRestangularizedElement, elem);
        elem[config.restangularFields.reqParams] = isEmpty(reqParams) ? null : reqParams;
        elem[config.restangularFields.withHttpConfig] = bind(withHttpConfig, elem);
        elem[config.restangularFields.plain] = bind(stripRestangular, elem, elem);
        // Tag element as restangularized
        elem[config.restangularFields.restangularized] = true;
        // RequestLess connection
        elem[config.restangularFields.one] = bind(one, elem, elem);
        elem[config.restangularFields.all] = bind(all, elem, elem);
        elem[config.restangularFields.several] = bind(several, elem, elem);
        elem[config.restangularFields.oneUrl] = bind(oneUrl, elem, elem);
        elem[config.restangularFields.allUrl] = bind(allUrl, elem, elem);
        elem[config.restangularFields.fromServer] = !!fromServer;
        if (parent && config.shouldSaveParent(route)) {
          const parentId = config.getIdFromElem(parent);
          const parentUrl = config.getUrlFromElem(parent);
          const restangularFieldsForParent = union(values(pick(config.restangularFields, ['route', 'singleOne', 'parentResource'])), config.extraFields);
          const parentResource = pick(parent, restangularFieldsForParent);
          if (config.isValidId(parentId)) {
            config.setIdToElem(parentResource, parentId, route);
          }
          if (config.isValidId(parentUrl)) {
            config.setUrlToElem(parentResource, parentUrl, route);
          }
          elem[config.restangularFields.parentResource] = parentResource;
        } else {
          elem[config.restangularFields.parentResource] = null;
        }
        return elem;
      }
      function one(parent, route, id, singleOne) {
        let error;
        if (isNumber(route) || isNumber(parent)) {
          error = 'You\'re creating a Restangular entity with the number ';
          error += 'instead of the route or the parent. For example, you can\'t call .one(12).';
          throw new Error(error);
        }
        if (isUndefined(route)) {
          error = 'You\'re creating a Restangular entity either without the path. ';
          error += 'For example you can\'t call .one(). Please check if your arguments are valid.';
          throw new Error(error);
        }
        const elem = {};
        config.setIdToElem(elem, id, route);
        config.setFieldToElem(config.restangularFields.singleOne, elem, singleOne);
        return restangularizeElem(parent, elem, route, false);
      }
      function all(parent, route) {
        return restangularizeCollection(parent, [], route, false);
      }
      function several(parent, route /*, ids */) {
        const collection = [];
        collection[config.restangularFields.ids] = Array.prototype.splice.call(arguments, 2);
        return restangularizeCollection(parent, collection, route, false);
      }
      function oneUrl(parent, route, url) {
        if (!route) {
          throw new Error('Route is mandatory when creating new Restangular objects.');
        }
        const elem = {};
        config.setUrlToElem(elem, url, route);
        return restangularizeElem(parent, elem, route, false);
      }
      function allUrl(parent, route, url) {
        if (!route) {
          throw new Error('Route is mandatory when creating new Restangular objects.');
        }
        const elem = {};
        config.setUrlToElem(elem, url, route);
        return restangularizeCollection(parent, elem, route, false);
      }
      // Promises
      function restangularizeResponse(subject, isCollection, valueToFill) {
        return subject.pipe(filter(res => !!res));
      }
      function resolvePromise(subject, response, data, filledValue) {
        extend(filledValue, data);
        // Trigger the full response interceptor.
        if (config.fullResponse) {
          subject.next(extend(response, {
            data: data
          }));
        } else {
          subject.next(data);
        }
        subject.complete();
      }
      // Elements
      function stripRestangular(elem) {
        if (isArray(elem)) {
          const array = [];
          each(elem, function (value) {
            array.push(config.isRestangularized(value) ? stripRestangular(value) : value);
          });
          return array;
        } else {
          return omit(elem, values(omit(config.restangularFields, 'id')));
        }
      }
      function addCustomOperation(elem) {
        elem[config.restangularFields.customOperation] = bind(customFunction, elem);
        const requestMethods = {
          get: customFunction,
          delete: customFunction
        };
        each(['put', 'patch', 'post'], function (name) {
          requestMethods[name] = function (operation, element, path, params, headers) {
            return bind(customFunction, this)(operation, path, params, headers, element);
          };
        });
        each(requestMethods, function (requestFunc, name) {
          const callOperation = name === 'delete' ? 'remove' : name;
          each(['do', 'custom'], function (alias) {
            elem[alias + name.toUpperCase()] = bind(requestFunc, elem, callOperation);
          });
        });
        elem[config.restangularFields.customGETLIST] = bind(fetchFunction, elem);
        elem[config.restangularFields.doGETLIST] = elem[config.restangularFields.customGETLIST];
      }
      function copyRestangularizedElement(fromElement, toElement = {}) {
        const copiedElement = Object.assign(toElement, fromElement);
        return restangularizeElem(copiedElement[config.restangularFields.parentResource], copiedElement, copiedElement[config.restangularFields.route], true);
      }
      function restangularizeElem(parent, element, route, fromServer, collection, reqParams) {
        const elem = config.onBeforeElemRestangularized(element, false, route);
        const localElem = restangularizeBase(parent, elem, route, reqParams, fromServer);
        if (config.useCannonicalId) {
          localElem[config.restangularFields.cannonicalId] = config.getIdFromElem(localElem);
        }
        if (collection) {
          localElem[config.restangularFields.getParentList] = function () {
            return collection;
          };
        }
        localElem[config.restangularFields.restangularCollection] = false;
        localElem[config.restangularFields.get] = bind(getFunction, localElem);
        localElem[config.restangularFields.getList] = bind(fetchFunction, localElem);
        localElem[config.restangularFields.put] = bind(putFunction, localElem);
        localElem[config.restangularFields.post] = bind(postFunction, localElem);
        localElem[config.restangularFields.remove] = bind(deleteFunction, localElem);
        localElem[config.restangularFields.head] = bind(headFunction, localElem);
        localElem[config.restangularFields.trace] = bind(traceFunction, localElem);
        localElem[config.restangularFields.options] = bind(optionsFunction, localElem);
        localElem[config.restangularFields.patch] = bind(patchFunction, localElem);
        localElem[config.restangularFields.save] = bind(save, localElem);
        addCustomOperation(localElem);
        return config.transformElem(localElem, false, route, service, true);
      }
      function restangularizeCollection(parent, element, route, fromServer, reqParams) {
        const elem = config.onBeforeElemRestangularized(element, true, route);
        const localElem = restangularizeBase(parent, elem, route, reqParams, fromServer);
        localElem[config.restangularFields.restangularCollection] = true;
        localElem[config.restangularFields.post] = bind(postFunction, localElem, null);
        localElem[config.restangularFields.remove] = bind(deleteFunction, localElem);
        localElem[config.restangularFields.head] = bind(headFunction, localElem);
        localElem[config.restangularFields.trace] = bind(traceFunction, localElem);
        localElem[config.restangularFields.putElement] = bind(putElementFunction, localElem);
        localElem[config.restangularFields.options] = bind(optionsFunction, localElem);
        localElem[config.restangularFields.patch] = bind(patchFunction, localElem);
        localElem[config.restangularFields.get] = bind(getById, localElem);
        localElem[config.restangularFields.getList] = bind(fetchFunction, localElem, null);
        addCustomOperation(localElem);
        return config.transformElem(localElem, true, route, service, true);
      }
      function restangularizeCollectionAndElements(parent, element, route) {
        const collection = restangularizeCollection(parent, element, route, false);
        each(collection, function (elem) {
          if (elem) {
            restangularizeElem(parent, elem, route, false);
          }
        });
        return collection;
      }
      function getById(id, reqParams, headers) {
        return this.customGET(id.toString(), reqParams, headers);
      }
      function putElementFunction(idx, params, headers) {
        const __this = this;
        const elemToPut = this[idx];
        const subject = new BehaviorSubject(null);
        let filledArray = [];
        filledArray = config.transformElem(filledArray, true, elemToPut[config.restangularFields.route], service);
        elemToPut.put(params, headers).subscribe(function (serverElem) {
          const newArray = copyRestangularizedElement(__this);
          newArray[idx] = serverElem;
          filledArray = newArray;
          subject.next(newArray);
        }, function (response) {
          subject.error(response);
        }, function () {
          subject.complete();
        });
        return restangularizeResponse(subject, true, filledArray);
      }
      function parseResponse(resData, operation, route, fetchUrl, response, subject) {
        const data = config.responseExtractor(resData, operation, route, fetchUrl, response, subject);
        const etag = response.headers.get('ETag');
        if (data && etag) {
          data[config.restangularFields.etag] = etag;
        }
        return data;
      }
      function fetchFunction(what, reqParams, headers) {
        const __this = this;
        const subject = new BehaviorSubject(null);
        const operation = 'getList';
        const url = urlHandler.fetchUrl(this, what);
        const whatFetched = what || __this[config.restangularFields.route];
        const request = config.fullRequestInterceptor(null, operation, whatFetched, url, headers || {}, reqParams || {}, this[config.restangularFields.httpConfig] || {});
        let filledArray = [];
        filledArray = config.transformElem(filledArray, true, whatFetched, service);
        let method = 'getList';
        if (config.jsonp) {
          method = 'jsonp';
        }
        const okCallback = function (response) {
          const resData = response.body;
          const fullParams = response.config.params;
          let data = parseResponse(resData, operation, whatFetched, url, response, subject);
          // support empty response for getList() calls (some APIs respond with 204 and empty body)
          if (isUndefined(data) || '' === data) {
            data = [];
          }
          if (!isArray(data)) {
            throw new Error('Response for getList SHOULD be an array and not an object or something else');
          }
          if (true === config.plainByDefault) {
            return resolvePromise(subject, response, data, filledArray);
          }
          let processedData = map$1(data, function (elem) {
            if (!__this[config.restangularFields.restangularCollection]) {
              return restangularizeElem(__this, elem, what, true, data);
            } else {
              return restangularizeElem(__this[config.restangularFields.parentResource], elem, __this[config.restangularFields.route], true, data);
            }
          });
          processedData = extend(data, processedData);
          if (!__this[config.restangularFields.restangularCollection]) {
            resolvePromise(subject, response, restangularizeCollection(__this, processedData, what, true, fullParams), filledArray);
          } else {
            resolvePromise(subject, response, restangularizeCollection(__this[config.restangularFields.parentResource], processedData, __this[config.restangularFields.route], true, fullParams), filledArray);
          }
        };
        urlHandler.resource(this, $http, request.httpConfig, request.headers, request.params, what, this[config.restangularFields.etag], operation)[method]().subscribe(okCallback, function error(response) {
          if (response.status === 304 && __this[config.restangularFields.restangularCollection]) {
            resolvePromise(subject, response, __this, filledArray);
          } else if (every(config.errorInterceptors, function (cb) {
            return cb(response, subject, okCallback) !== false;
          })) {
            // triggered if no callback returns false
            subject.error(response);
          }
        });
        return restangularizeResponse(subject, true, filledArray);
      }
      function withHttpConfig(httpConfig) {
        this[config.restangularFields.httpConfig] = httpConfig;
        return this;
      }
      function save(params, headers) {
        if (this[config.restangularFields.fromServer]) {
          return this[config.restangularFields.put](params, headers);
        } else {
          return bind(elemFunction, this)('post', undefined, params, undefined, headers);
        }
      }
      function elemFunction(operation, what, params, obj, headers) {
        const __this = this;
        const subject = new BehaviorSubject(null);
        const resParams = params || {};
        const route = what || this[config.restangularFields.route];
        const fetchUrl = urlHandler.fetchUrl(this, what);
        let callObj = obj || this;
        // fallback to etag on restangular object (since for custom methods we probably don't explicitly specify the etag field)
        const etag = callObj[config.restangularFields.etag] || (operation !== 'post' ? this[config.restangularFields.etag] : null);
        if (isObject(callObj) && config.isRestangularized(callObj)) {
          callObj = stripRestangular(callObj);
        }
        const request = config.fullRequestInterceptor(callObj, operation, route, fetchUrl, headers || {}, resParams || {}, this[config.restangularFields.httpConfig] || {});
        let filledObject = {};
        filledObject = config.transformElem(filledObject, false, route, service);
        const okCallback = function (response) {
          const resData = get(response, 'body');
          const fullParams = get(response, 'config.params');
          const elem = parseResponse(resData, operation, route, fetchUrl, response, subject);
          if (elem) {
            let data;
            if (true === config.plainByDefault) {
              return resolvePromise(subject, response, elem, filledObject);
            }
            if (operation === 'post' && !__this[config.restangularFields.restangularCollection]) {
              data = restangularizeElem(__this[config.restangularFields.parentResource], elem, route, true, null, fullParams);
              resolvePromise(subject, response, data, filledObject);
            } else {
              data = restangularizeElem(__this[config.restangularFields.parentResource], elem, __this[config.restangularFields.route], true, null, fullParams);
              data[config.restangularFields.singleOne] = __this[config.restangularFields.singleOne];
              resolvePromise(subject, response, data, filledObject);
            }
          } else {
            resolvePromise(subject, response, undefined, filledObject);
          }
        };
        const errorCallback = function (response) {
          if (response.status === 304 && config.isSafe(operation)) {
            resolvePromise(subject, response, __this, filledObject);
          } else if (every(config.errorInterceptors, function (cb) {
            return cb(response, subject, okCallback) !== false;
          })) {
            // triggered if no callback returns false
            subject.error(response);
          }
        };
        // Overriding HTTP Method
        let callOperation = operation;
        let callHeaders = extend({}, request.headers);
        const isOverrideOperation = config.isOverridenMethod(operation);
        if (isOverrideOperation) {
          callOperation = 'post';
          callHeaders = extend(callHeaders, {
            'X-HTTP-Method-Override': operation === 'remove' ? 'DELETE' : operation.toUpperCase()
          });
        } else if (config.jsonp && callOperation === 'get') {
          callOperation = 'jsonp';
        }
        if (config.isSafe(operation)) {
          if (isOverrideOperation) {
            urlHandler.resource(this, $http, request.httpConfig, callHeaders, request.params, what, etag, callOperation)[callOperation]({}).subscribe(okCallback, errorCallback);
          } else {
            urlHandler.resource(this, $http, request.httpConfig, callHeaders, request.params, what, etag, callOperation)[callOperation]().subscribe(okCallback, errorCallback);
          }
        } else {
          urlHandler.resource(this, $http, request.httpConfig, callHeaders, request.params, what, etag, callOperation)[callOperation](request.element).subscribe(okCallback, errorCallback);
        }
        return restangularizeResponse(subject, false, filledObject);
      }
      function getFunction(params, headers) {
        return bind(elemFunction, this)('get', undefined, params, undefined, headers);
      }
      function deleteFunction(params, headers) {
        return bind(elemFunction, this)('remove', undefined, params, undefined, headers);
      }
      function putFunction(params, headers) {
        return bind(elemFunction, this)('put', undefined, params, undefined, headers);
      }
      function postFunction(what, elem, params, headers) {
        return bind(elemFunction, this)('post', what, params, elem, headers);
      }
      function headFunction(params, headers) {
        return bind(elemFunction, this)('head', undefined, params, undefined, headers);
      }
      function traceFunction(params, headers) {
        return bind(elemFunction, this)('trace', undefined, params, undefined, headers);
      }
      function optionsFunction(params, headers) {
        return bind(elemFunction, this)('options', undefined, params, undefined, headers);
      }
      function patchFunction(elem, params, headers) {
        return bind(elemFunction, this)('patch', undefined, params, elem, headers);
      }
      function customFunction(operation, path, params, headers, elem) {
        return bind(elemFunction, this)(operation, path, params, elem, headers);
      }
      function addRestangularMethodFunction(name, operation, path, defaultParams, defaultHeaders, defaultElem) {
        let bindedFunction;
        if (operation === 'getList') {
          bindedFunction = bind(fetchFunction, this, path);
        } else {
          bindedFunction = bind(customFunction, this, operation, path);
        }
        const createdFunction = function (params, headers, elem) {
          const callParams = defaults({
            params: params,
            headers: headers,
            elem: elem
          }, {
            params: defaultParams,
            headers: defaultHeaders,
            elem: defaultElem
          });
          return bindedFunction(callParams.params, callParams.headers, callParams.elem);
        };
        if (config.isSafe(operation)) {
          this[name] = createdFunction;
        } else {
          this[name] = function (elem, params, headers) {
            return createdFunction(params, headers, elem);
          };
        }
      }
      function withConfigurationFunction(configurer) {
        const newConfig = clone(omit(config, 'configuration'));
        RestangularConfigurer(newConfig, newConfig);
        configurer(newConfig);
        return createServiceForConfiguration(newConfig);
      }
      function toService(route, parent) {
        const knownCollectionMethods = values(config.restangularFields);
        const serv = {};
        const collection = (parent || service).all(route);
        serv.one = bind(one, parent || service, parent, route);
        serv.all = bind(collection.all, collection);
        serv.post = bind(collection.post, collection);
        serv.getList = bind(collection.getList, collection);
        serv.withHttpConfig = bind(collection.withHttpConfig, collection);
        serv.get = bind(collection.get, collection);
        for (const prop in collection) {
          if (collection.hasOwnProperty(prop) && isFunction(collection[prop]) && !includes(knownCollectionMethods, prop)) {
            serv[prop] = bind(collection[prop], collection);
          }
        }
        return serv;
      }
      RestangularConfigurer(service, config);
      service.copy = bind(copyRestangularizedElement, service);
      service.service = bind(toService, service);
      service.withConfig = bind(withConfigurationFunction, service);
      service.one = bind(one, service, null);
      service.all = bind(all, service, null);
      service.several = bind(several, service, null);
      service.oneUrl = bind(oneUrl, service, null);
      service.allUrl = bind(allUrl, service, null);
      service.stripRestangular = bind(stripRestangular, service);
      service.restangularizeElement = bind(restangularizeElem, service);
      service.restangularizeCollection = bind(restangularizeCollectionAndElements, service);
      return service;
    }
    return createServiceForConfiguration(globalConfiguration);
  }
}
const CONFIG_OBJ = new InjectionToken('configObj');
class RestangularModule {
  constructor(parentModule) {
    if (parentModule) {
      throw new Error('RestangularModule is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot(config1, config2) {
    return {
      ngModule: RestangularModule,
      providers: [{
        provide: CONFIG_OBJ,
        useValue: [config1, config2]
      }, {
        provide: RESTANGULAR,
        useFactory: RestangularFactory,
        deps: [CONFIG_OBJ]
      }]
    };
  }
}
/** @nocollapse */
RestangularModule.ɵfac = function RestangularModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RestangularModule)(i0.ɵɵinject(RestangularModule, 12));
};
/** @nocollapse */
RestangularModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RestangularModule
});
/** @nocollapse */
RestangularModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [RestangularHttp, Restangular],
  imports: [HttpClientModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RestangularModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule],
      providers: [RestangularHttp, Restangular]
    }]
  }], function () {
    return [{
      type: RestangularModule,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }];
  }, null);
})();

/*
 * Public API Surface of ngx-restangular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Restangular, RestangularHttp, RestangularModule };

