import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { map } from "rxjs/operators";
import moment from "moment";
import { environment } from "../../../environments/environment";

import { Router } from "@angular/router";
import { BehaviorSubject, delay, Observable, of, Subscription } from "rxjs";
import { User } from "../../models/user";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { config } from "../../app.config";
import { NgxSpinnerService } from "ngx-spinner";


@Injectable()
export class AuthService {

    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    tokenSubscription = new Subscription()

    constructor(
        private http: HttpClient,
        private idle: Idle,
        private router: Router,
        private spinner: NgxSpinnerService,
        public jwtHelper: JwtHelperService
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();

        idle.setIdle(30); // how long can they be inactive before considered idle, in seconds
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
        idle.onIdleStart.subscribe(() => {
            console.log('User ist inaktiv', moment().format('HH:mm:ss'))
        });
        // do something when the user is no longer idle
        idle.onIdleEnd.subscribe(() => {
            console.log('Und er bewegt sich doch', moment().format('HH:mm:ss'));
        });

        idle.onTimeout.subscribe(() => this.logout());
    }

    public get userValue(): User {
        let user = this.userSubject.value;
        if (user === null) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (user) {
            user.isSuperAdmin = user.roles[0] == 'ROLE_SUPER_ADMIN';
        }
        return user;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiHost}/auth`, {username, password})
            .pipe(
                map((result) => {
                    const timeout = this.jwtHelper.getTokenExpirationDate(result.token).valueOf() - new Date().valueOf();
                    // tokenExpiration and stuff is in milliseconds
                    this.expirationCounter(config.auth.idleTimeout * 1000);
                    return this.storeUserData(result);
                })
            );
    }

    storeUserData(result: any): any {
        const decoded = this.jwtHelper.decodeToken(result.token);
        const lang = decoded.lang;
        localStorage.setItem('lang', lang);
        const user: User = decoded;
        user.token = result.token;
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
    }

    public isAuthenticated(): boolean {
        const user: any = localStorage.getItem('user');
        const token = user.token;
        // Check whether the token is expired and return
        // true or false
        return !this.jwtHelper.isTokenExpired(token);
    }

    expirationCounter(timeout) {
        console.log(timeout / 1000);
        this.idle.setTimeout(timeout/1000);
        this.idle.watch();
    }


    logout() {
        console.log('ausgeloggt', moment().format('HH:mm:ss'));
        this.http.get(`${environment.apiHost}/users/${this.userValue.id}`)
            .subscribe((user: any) => {
                Object.assign(user, {online: false})
                this.http.put(`${environment.apiHost}/users/${user.id}`, user).subscribe();
                this.spinner.hide();
                this.tokenSubscription.unsubscribe();
                this.removeSession();
                this.router.navigate([ '/login' ]);
            });
    }

    removeSession() {
        localStorage.removeItem('user');
        localStorage.removeItem('lang');
        this.userSubject.next(null);
    }

    checkWrongPasswordCount(username: string): Observable<any> {
        let wrongPasswordCount =  parseInt(localStorage.getItem('wrongPasswordCount'));

        if (typeof wrongPasswordCount == 'undefined' || isNaN(wrongPasswordCount)) {
            wrongPasswordCount = 1;
        } else {
            wrongPasswordCount++
        }

        let counterObservable = null;
        if (wrongPasswordCount >= 3) {
            counterObservable = this.lockAccount(username);
        } else {
            counterObservable = of('');
        }

        localStorage.setItem('wrongPasswordCount', wrongPasswordCount.toString());
        return counterObservable;
    }

    private lockAccount(username: string) {
        return this.http.get(`${environment.apiHost}/user-lock?username=${username}`);
    }
}
